import * as React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import { INFO_BLOCK_TYPE } from '../../../../consts/infoblock';
import { InfoBlockContactUs } from './InfoBlock/InfoBlockContactUs';
import { InfoBlockTerms } from './InfoBlock/InfoBlockTerms';
import { InfoBlockAboutUs } from './InfoBlock/InfoBlockAboutUs';
import { InfoBlockDelivery } from './InfoBlock/InfoBlockDelivery';

interface Props {
  isOpenInfoBlock: boolean;
  infoBlockType: string;
  onShowInfoBlock: (type) => void;
}

export function Footer(props: Props) {
  const { isOpenInfoBlock, infoBlockType, onShowInfoBlock } = props;

  const renderInfoBlock = () => {
    switch (true) {
      case infoBlockType === INFO_BLOCK_TYPE.CONTACT_US:
        return <InfoBlockContactUs />;
      case infoBlockType === INFO_BLOCK_TYPE.TERMS_AND_CONDITIONS:
        return <InfoBlockTerms />;
      case infoBlockType === INFO_BLOCK_TYPE.ABOUT_US:
        return <InfoBlockAboutUs />;
      case infoBlockType === INFO_BLOCK_TYPE.DELIVERY:
        return <InfoBlockDelivery />;
    }
  };

  const className = isOpenInfoBlock ? 'opened' : 'closed';
  return (
    <div className="bFooterWrapper">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="hiddenWrapper">
              <div className={className}>{renderInfoBlock()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bSliderWrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bSlider">
                <div className="eLostTicketContainer">
                  <a href="https://bentleyphoto.com/lost-childs-ticket/" target="_blank" rel="noopener noreferrer">
                    <img className="eSliderImageSingle" src="/dist/images/icon/lostTickets.png" alt="Lost Tickets" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="eFooterWorldPayIcon">
              <img src="/dist/images/worldpay.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <div className="bFooterMenuTitle mt-3 mb-3">Help</div>
            <div className="bFooterMenu">
              <div className="bFooterMenuItem">
                <Link
                  to={{ pathname: '/' }}
                  onClick={() => {
                    onShowInfoBlock(INFO_BLOCK_TYPE.CONTACT_US);
                  }}
                >
                  Contact us
                </Link>
              </div>
              <div className="bFooterMenuItem">
                <Link to={{ pathname: '/parentFAQs' }} target="_blank">
                  FAQ's
                </Link>
              </div>
              <div className="bFooterMenuItem">
                <Link
                  to={{ pathname: '/' }}
                  onClick={() => {
                    onShowInfoBlock(INFO_BLOCK_TYPE.DELIVERY);
                  }}
                >
                  Delivery
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="bFooterMenuTitle mt-3 mb-3">Information</div>
            <div className="bFooterMenu">
              <div className="bFooterMenuItem">
                <Link to={{ pathname: '/GDPRInformation' }} target="_blank">
                  GDPR information
                </Link>
              </div>
              <div className="bFooterMenuItem">
                <Link
                  to={{ pathname: '/' }}
                  onClick={() => {
                    onShowInfoBlock(INFO_BLOCK_TYPE.TERMS_AND_CONDITIONS);
                  }}
                >
                  Terms And Conditions
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="bFooterMenuTitle mt-3 mb-3">About us</div>
            <div className="bFooterMenu">
              <div className="bFooterMenuItem">
                <Link
                  to={{ pathname: '/' }}
                  onClick={() => {
                    onShowInfoBlock(INFO_BLOCK_TYPE.ABOUT_US);
                  }}
                >
                  About us
                </Link>
              </div>
              <div className="bFooterMenuItem">
                <Link
                  to={{ pathname: '/login' }}
                  onClick={() => {
                    onShowInfoBlock(INFO_BLOCK_TYPE.LOGIN);
                  }}
                >
                  Log In
                </Link>
              </div>
              <div className="bFooterMenuItem">
                <a href="https://apps.apple.com/us/app/schoolphoto/id1523040291" target="_blank">
                  <img className="eLogoWrapper" src="/dist/images/app_store.png" />
                </a>
              </div>
              <div className="bFooterMenuItem">
                <a href="https://play.google.com/store/apps/details?id=com.schoolphoto" target="_blank">
                  <img className="eLogoWrapper" src="/dist/images/google_play.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
